import React,{useState,useEffect} from "react";
import { Link } from "react-router-dom";

import logoDark from "../../assets/images/logo-dark.png"
import logoLight from "../../assets/images/logo-light.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

export default function NavbarTwo({navClass,manuClass,navDark}){
    const [scroll, setScroll] = useState(false);
    const [isMenu, setisMenu] = useState(false);

    useEffect(() => {
        activateMenu()
        window.addEventListener("scroll", () => {
          setScroll(window.scrollY > 50);
        });
        window.scrollTo({ top: 0, behavior: 'smooth' });

        // const handleClickOutside =()=>{
        //     setSearchBox(false)
        // }
        // document.addEventListener("mousedown", handleClickOutside);
      }, []);

      var mybutton = document.getElementById("back-to-top");
        window.onscroll = function () {
            scrollFunction();
        };

        function scrollFunction() {
            if(mybutton!=null){
                if (document.body.scrollTop > 500 || document.documentElement.scrollTop > 500) {
                    mybutton.style.display = "block";
                } else {
                    mybutton.style.display = "none";
                }
            }
        }
     
        // Toggle menu
        const toggleMenu = () => {
            setisMenu(!isMenu);
            if (document.getElementById("navigation")) {
                const anchorArray = Array.from(document.getElementById("navigation").getElementsByTagName("a"));
                anchorArray.forEach(element => {
                    element.addEventListener('click', (elem) => {
                        const target = elem.target.getAttribute("href")
                        if (target !== "") {
                            if (elem.target.nextElementSibling) {
                                var submenu = elem.target.nextElementSibling.nextElementSibling;
                                submenu.classList.toggle('open');
                            }
                        }
                    })
                });
            }
        };

//Menu Active
        function getClosest(elem, selector) {

            // Element.matches() polyfill
            if (!Element.prototype.matches) {
                Element.prototype.matches =
                    Element.prototype.matchesSelector ||
                    Element.prototype.mozMatchesSelector ||
                    Element.prototype.msMatchesSelector ||
                    Element.prototype.oMatchesSelector ||
                    Element.prototype.webkitMatchesSelector ||
                    function (s) {
                        var matches = (this.document || this.ownerDocument).querySelectorAll(s),
                            i = matches.length;
                        while (--i >= 0 && matches.item(i) !== this) { }
                        return i > -1;
                    };
            }

            // Get the closest matching element
            for (; elem && elem !== document; elem = elem.parentNode) {
                if (elem.matches(selector)) return elem;
            }
            return null;

        };

        function activateMenu() {
            var menuItems = document.getElementsByClassName("sub-menu-item");
            if (menuItems) {

                var matchingMenuItem = null;
                for (var idx = 0; idx < menuItems.length; idx++) {
                    if (menuItems[idx].href === window.location.href) {
                        matchingMenuItem = menuItems[idx];
                    }
                }

                if (matchingMenuItem) {
                    matchingMenuItem.classList.add('active');
                
                
                    var immediateParent = getClosest(matchingMenuItem, 'li');
            
                    if (immediateParent) {
                        immediateParent.classList.add('active');
                    }
                    
                    var parent = getClosest(immediateParent, '.child-menu-item');
                    if(parent){
                        parent.classList.add('active');
                    }

                    var parent = getClosest(parent || immediateParent , '.parent-menu-item');
                
                    if (parent) {
                        parent.classList.add('active');

                        var parentMenuitem = parent.querySelector('.menu-item');
                        if (parentMenuitem) {
                            parentMenuitem.classList.add('active');
                        }

                        var parentOfParent = getClosest(parent, '.parent-parent-menu-item');
                        if (parentOfParent) {
                            parentOfParent.classList.add('active');
                        }
                    } else {
                        var parentOfParent = getClosest(matchingMenuItem, '.parent-parent-menu-item');
                        if (parentOfParent) {
                            parentOfParent.classList.add('active');
                        }
                    }
                }
            }
        }


        // Clickable Menu
        if(document.getElementById("navigation")){
            var elements = document.getElementById("navigation").getElementsByTagName("a");
            for(var i = 0, len = elements.length; i < len; i++) {
                elements[i].onclick = function (elem) {
                    if(elem.target.getAttribute("href") === "#") {
                        var submenu = elem.target.nextElementSibling.nextElementSibling;
                        submenu.classList.toggle('open');
                    }
                }
            }
        }
    return(
        <>
         <header id="topnav" className={`${scroll ? "nav-sticky" :""} ${navClass}`}>
            <div id="custom-container" className="container">
                {navDark === true ?  
                <Link className="logo" to="/">
                    <img src={logoDark} className="logo-light-mode" alt=""/>
                    <img src={logoLight} className="logo-dark-mode" alt=""/>
                </Link> :
                <Link className="logo" to="/">
                    <span className="logo-light-mode">
                        <img src={logoDark} className="l-dark" alt=""/>
                        <img src={logoLight} className="l-light" alt=""/>
                    </span>
                    <img src={logoLight} className="logo-dark-mode" alt=""/>
                </Link>
                }
                <div className="menu-extras">
                    <div className="menu-item">
                        <Link to="#" className={`navbar-toggle ${isMenu ? 'open' : ''}`} id="isToggle" onClick={() => toggleMenu()}>
                            <div className="lines">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </Link>
                    </div>
                </div>

                <div id="navigation" style={{ display: isMenu ? 'block' : 'none' }}>
                    <ul className={manuClass}>
                        <li className="parent-parent-menu-item">
                            <Link to="/">Home</Link><span className="menu-arrow"></span>
                        </li>
                        <li className="has-submenu parent-parent-menu-item">
                            <Link to="/who-we-are">Who We Are</Link><span className="menu-arrow"></span>
                            <ul className="submenu">
                                <li><Link to="/who-we-are" className="sub-menu-item">Mission, Vision, Values</Link></li>
                                <li><Link to="/who-we-are" className="sub-menu-item">Statement of Non-Discrimination</Link></li>
                            </ul>
                        </li>
                        <li className="has-submenu parent-parent-menu-item">
                            <Link to="/term-life">Term Life</Link><span className="menu-arrow"></span>
                            <ul className="submenu">
                                <li><Link to="/term-life" className="sub-menu-item">Type 1</Link></li>
                                <li><Link to="/term-life" className="sub-menu-item">Type 2</Link></li>
                                <li><Link to="/term-life" className="sub-menu-item">Type 3</Link></li>
                                <li><Link to="/term-life" className="sub-menu-item">Type 4</Link></li>
                                <li><Link to="/term-life" className="sub-menu-item">Type 5</Link></li>
                            </ul>
                        </li>
                        <li className="has-submenu parent-parent-menu-item">
                            <Link to="/perm-life">Permanent Life</Link><span className="menu-arrow"></span>
                            <ul className="submenu">
                                <li><Link to="/perm-life" className="sub-menu-item">Type 1</Link></li>
                                <li><Link to="/perm-life" className="sub-menu-item">Type 2</Link></li>
                                <li><Link to="/perm-life" className="sub-menu-item">Type 3</Link></li>
                                <li><Link to="/perm-life" className="sub-menu-item">Type 4</Link></li>
                                <li><Link to="/perm-life" className="sub-menu-item">Type 5</Link></li>
                            </ul>
                        </li>
                        <li className="has-submenu parent-parent-menu-item">
                            <Link to="/perm-life">LTC & Medicare</Link><span className="menu-arrow"></span>
                            <ul className="submenu">
                                <li><Link to="/ltc-medicare" className="sub-menu-item">Long Term Care</Link></li>
                                <li><Link to="/ltc-medicare" className="sub-menu-item">Medicare Supplement Plans</Link></li>
                            </ul>
                        </li>
                        <li className="has-submenu parent-parent-menu-item">
                            <Link to="#">More</Link><span className="menu-arrow"></span>
                            <ul className="submenu megamenu">
                                <li>
                                    <ul>
                                        <li className="megamenu-head">Building Wealth</li>
                                        <li><Link to="/building-wealth" className="sub-menu-item">Words Of Encouragement</Link></li>
                                        <li><Link to="/building-wealth" className="sub-menu-item">Budgets 101</Link></li>
                                        <li><Link to="/building-wealth" className="sub-menu-item">Financial Calculators</Link></li>
                                    </ul>
                                </li>

                                <li>
                                    <ul>
                                        <li className="megamenu-head">Considerations</li>
                                        <li><Link to="/portfolio-creative-two" className="sub-menu-item">Establishing Trusts</Link></li>
                                        <li><Link to="/portfolio-creative-three" className="sub-menu-item">Trusts vs Wills</Link></li>
                                        <li><Link to="/portfolio-creative-four" className="sub-menu-item">Wills vs TOD / POD</Link></li>
                                    </ul>
                                </li>

                                <li>
                                    <ul>
                                        <li className="megamenu-head">Blogs</li>
                                        <li><Link to="/portfolio-modern-two" className="sub-menu-item">Insurance</Link></li>
                                        <li><Link to="/portfolio-modern-three" className="sub-menu-item">The Lighter Side</Link></li>
                                        <li><Link to="/portfolio-modern-four" className="sub-menu-item">Recipies</Link></li>
                                    </ul>
                                </li>

                                <li>
                                    <ul>
                                        <li className="megamenu-head">Social Media</li>
                                        <li><Link to="https://www.facebook.com/profile.php?id=61573685685409#" className="sub-menu-item">Facebook</Link></li>
                                        <li><Link to="/portfolio-masonry-three" className="sub-menu-item">Instagram</Link></li>
                                        <li><Link to="/portfolio-masonry-four" className="sub-menu-item">YouTube</Link></li>
                                        <li><Link to="/portfolio-masonry-five" className="sub-menu-item">LinkedIn</Link></li>
                                        <li><Link to="/portfolio-masonry-six" className="sub-menu-item">BlueSky</Link></li>
                                    </ul>
                                </li>
                            </ul>
                        </li>

                        <li><Link to="/page-contact" className="sub-menu-item">Request a Quote <FontAwesomeIcon icon={faArrowRight} /></Link></li>
                    </ul>
                </div>
            </div>
        </header>
        </>
    )
}