import React from "react";
import { Route, Routes} from "react-router-dom";
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import './assets/scss/style.scss'
import './assets/css/materialdesignicons.min.css'
import IndexBusiness from "./pages/index/index-business";
import WhoWeAre from "./pages/page-who-we-are";
import TermLife from "./pages/page-termlife";
import PermLife from "./pages/page-permlife";
import LTCMedicare from "./pages/page-ltc-medicare";
import BuildingWealth from "./pages/page-building-wealth";
import Considerations from "./pages/page-considerations";
import InsuranceBlogHome from "./pages/blog/insurance/page-insurance-blog-home";
import InsuranceBlog1 from "./pages/blog/insurance/page-insurance-blog-1";
import TheLighterSideBlogHome from "./pages/blog/the-lighter-side/page-the-lighter-side-blog-home";
import TheLighterSideBlog1 from "./pages/blog/the-lighter-side/page-the-lighter-side-blog-1";
import RecipiesBlogHome from "./pages/blog/recipies/page-recipies-blog-home";
import RecipiesBlog1 from "./pages/blog/recipies/page-recipies-blog-1";
import Comingsoon from "./pages/spcial/page-comingsoon";
import Maintenance from "./pages/spcial/page-maintenance";
import Error from "./pages/spcial/page-error";

function App() {
  return (
   <>
   <Routes>
     <Route path="/" element={<IndexBusiness/>}/>
     <Route path="/who-we-are" element={<WhoWeAre/>}/>
     <Route path="/term-life" element={<TermLife/>}/>
     <Route path="/perm-life" element={<PermLife/>}/>
     <Route path="/ltc-medicare" element={<LTCMedicare/>}/>
     <Route path="/building-wealth" element={<BuildingWealth/>}/>
     <Route path="/considerations" element={<Considerations/>}/>
     <Route path="/InsuranceBlogHome" element={<InsuranceBlogHome/>}/>
     <Route path="/InsuranceBlog1" element={<InsuranceBlog1/>}/>
     <Route path="/TheLighterSideBlogHome" element={<TheLighterSideBlogHome/>}/>
     <Route path="/TheLighterSideBlog1" element={<TheLighterSideBlog1/>}/>
     <Route path="/RecipiesBlogHome" element={<RecipiesBlogHome/>}/>
     <Route path="/RecipiesBlog1" element={<RecipiesBlog1/>}/> 
     <Route path="/page-comingsoon" element={<Comingsoon/>}/>
     <Route path="/page-maintenance" element={<Maintenance/>}/>
     <Route path="/page-error" element={<Error/>}/>
     <Route path="*" element={<Error/>}/>
   </Routes>
   </>
  );
}

export default App;
